@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
    li {
        @apply p-4
    }
   
}
.font-kumbh {
    font-family: 'Kumbh Sans', sans-serif;
}
.hover-underline::after {
    content: "";
    display: block;
    width: 0;
    height: 3px;
    background-color: #4f46e5; /* Customize the underline color */
    position: absolute;
    bottom: 0;
    left: 0;
    transition: width 0.3s ease; /* Customize the transition duration */
}

.hover-underline:hover::after {
    width: 100%;
}

.my:hover .custom {
    
    filter: invert(100%);
    
    
    
}


.extended-portfolio-container {
    opacity: 0;
    transform: translateY(100px) scale(0.9);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  
  .extended-portfolio-container.active {
    opacity: 1;
    transform: translateY(0) scale(1); 
  }
